import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal)

export const SignupAlertError = (data) => {
    MySwal.fire({
        icon: "error",
        title: data
    })
}

export const SignupSuccessful = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export const LoginAlertError = ( data ) => {
    MySwal.fire({
        icon: "error",
        title: data
    })
}

export const LoginSuccessful = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export const createVideoAlertError = ( data ) => {
    MySwal.fire({
        icon: "error",
        title: data
    })
}

export const UpdateMemberBasicSuccessful = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export const CreateVideoSuccessful = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export const updateVideoAlertError = ( data ) => {
    MySwal.fire({
        icon: "error",
        title: data
    })
}

export const updateUserBasicAlertError = () => {
    MySwal.fire({
        icon: "error",
        title: "欄位資訊錯誤"
    })
}

export const updateUserAvatarAlertError = (data) => {
    MySwal.fire({
        icon: "error",
        title: data
    })
}