import {
  homePath,
  loginPath,
  memberPath,
  videoPath,
  loginEndpoint,
  signupEndpoint,
  userEndpoint,
  userVideosEndpoints,
  updateUserEndpoint,
  updateUserAvatarEndpoint,
  searchVideoEndpoint,
  userVideoEndpoint,
  createVideoEndpoint,
  editVideoEndpoint,
  deleteVideoEndpoint,
  videoSize
} from "../global/constants";
import {
  updateUserAvatarAlertError,
  updateUserBasicAlertError,
  LoginAlertError,
  LoginSuccessful,
  SignupAlertError,
  SignupSuccessful,
  CreateVideoSuccessful,
  createVideoAlertError,
  updateVideoAlertError,
  UpdateMemberBasicSuccessful
} from "./sweetalert";


// 首頁驗證 API
export async function checkIsAuthenticateHome(setAuthenticate, setData) {
  const res = await fetch(userEndpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("auth"),
    },
  });
  const data = await res.json();

  if (res.status === 200) {
    setAuthenticate(true);
    setData({
      email: data["email"]
    })
  } else {
    setData("");
  }
}

// 需要授權頁面導向 API
export async function checkIsAuthenticate(setAuthenticate, navigate) {
  const res = await fetch(userEndpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("auth"),
    },
  });

  if (res.status === 200) {
    setAuthenticate(true);
  } else {
    navigate(loginPath);
  }

}

// 登入與註冊頁面導向 API
export async function AuthenticateRedirect(navigate) {
  const res = await fetch(userEndpoint,{
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": localStorage.getItem("auth"),
    }
  })

  if (res.status === 200) {
    navigate(memberPath);
  } else {
    localStorage.removeItem("auth");
  }
}

// 取得會員資訊 API
export async function getMember(setAuthenticate, setNickname, setAvatarLink, setData, navigate) {
  const res = await fetch(userEndpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("auth"),
    },
  });
  const data = await res.json();
  if (res.status === 200) {
    setAuthenticate(true);
    setData({
      id: data["id"],
      email: data["email"],
      nickname: data["nickname"] || "",
      createdAt: data["created_at"],
      avatar: data["avatar"],
      active: data["active"],
    });
    setNickname(data["nickname"] || "");
    setAvatarLink(data["avatar"])
  } else if (res.status === 401 || res.status === 403) {
    navigate(loginPath);
  }
}

// 取得會員單一影片資訊 API
export async function getUserVideo(id, setdata, setAuthenticate, setName, setInformation, setVideoLink, navigate) {
  const res = await fetch(`${userVideoEndpoint}/${id}/video`, {
    method: "GET",
    headers: {      
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("auth"),
    }
  })

  const data = await res.json();

  if (res.status === 200) {
    setAuthenticate(true);
    setdata({
      name: data["name"],
      information: data["information"],
      url: data["url"]
    });
    setName(data["name"]);
    setInformation(data["information"]);
    setVideoLink(data["url"]);
  } else if (res.status === 404 || res.status === 422) {
    navigate(homePath)
  }

}

// 取得會員所有影片資訊 API
export async function getUserVideos(currentPage, setAuthenticate, setData, navigate, ref) {
  const res = await fetch(`${userVideosEndpoints}?page=${currentPage}&size=${videoSize}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("auth"),
    },
  });
  const data = await res.json();

  if (res.status === 200) {
    if (!ref.current) {
      setAuthenticate(true);
      ref.current = true;
    }

    setData({
      id: data["id"],
      email: data["email"],
      videos: data["videos"]
    });

  } else if (res.status === 401 || res.status === 403) {
    navigate(loginPath);
  }
}

// 更新會員大頭貼 API 
export async function updateUserAvatar(updateData, setAvatarLink) {
  const res = await fetch(updateUserAvatarEndpoint, {
    method: "PATCH",
    headers: {
      Authorization: localStorage.getItem("auth"),
    },
    body: updateData,
  });
  const data = await res.json();
  if (res.status === 200) {
    setAvatarLink(data["avatar"]);
  } else if (res.status === 400) {
    updateUserAvatarAlertError(data["detail"]);
  }
}

// 登出 API
export async function sendLogoutEndpoint(navigate, location) {
  localStorage.removeItem("auth");
  if (location.pathname === homePath) {
    window.location.reload();
  } else {
    navigate(homePath);
  }
}

// 登入 API
export async function sendLoginEndpoint(loginData, navigate) {
  try {
    const res = await fetch(loginEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: loginData.email,
        password: loginData.password,
      }),
    });
    const data = await res.json();

    if (res.status === 200) {
      localStorage.setItem(
        "auth",
        `${data["token_type"]} ${data["access_token"]}`,
      );

      LoginSuccessful.fire({
        icon: "success",
        title: "登入成功"
      }).then(() => {
        navigate(homePath);
      });
    }

    if (res.status === 404) {
      LoginAlertError(data["detail"]);
    } else if (res.status === 401) {
      LoginAlertError(data["detail"]);
    }
  } catch (err) {
    console.error(err);
  }
}

// 註冊 API
export async function sendSignupEndpoint(SignupData, navigate) {
  try {
    const res = await fetch(signupEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: SignupData.email,
        password: SignupData.password,
      }),
    });
    const data = await res.json();

    if (res.status === 201) {
      SignupSuccessful.fire({
        icon: "success",
        title: "註冊成功",
      }).then(() => {
        navigate(loginPath);
      })

    } else if (res.status === 400) {
      SignupAlertError(data["detail"]);
    }
  } catch (err) {
    console.error(err);
  }
}

// 修改會員基本資料 API
export async function updateUser(updateData, setNickname) {
  const res = await fetch(updateUserEndpoint, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("auth"),
    },
    body: JSON.stringify({ ...updateData }),
  });
  const data = await res.json();

  if (res.status === 200) {
    UpdateMemberBasicSuccessful.fire({
      icon: "success",
      title: "更新資料成功"
    }).then(() => {
      setNickname(data["nickname"]);
    })

  } else if (res.status === 422) {
    updateUserBasicAlertError();
  }
}

// 新增影片 API
export async function createVideo(formData, navigate) {
  const res = await fetch(createVideoEndpoint, {
      method: "POST",
      headers: {
          "Authorization": localStorage.getItem("auth")
      },
      body: formData
  })

  const data = await res.json();

  if (res.status === 201) {
      CreateVideoSuccessful.fire({
          icon: "success",
          title: "影片新增成功，請等待時間轉檔",
      }).then(() => {
          navigate(videoPath);
      })

  } else {
      createVideoAlertError(data["detail"]);
  }
}

// 更新影片 API
export async function updateVideo(updateData, navigate) {
  const res = await fetch(`${editVideoEndpoint}/${updateData['id']}/video`, {
      method: "PATCH",
      headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("auth"),
      },
      body: JSON.stringify({
          name: updateData['name'],
          information: updateData['information']
      }),
  });

  const data = await res.json();

  if (res.status === 200) {
      navigate(videoPath);
  } else if (res.status === 400) {
      updateVideoAlertError(data["detail"]);
  } else if (res.status === 404) {
      updateVideoAlertError(data["detail"]);
  }
}

// 刪除影片 API
export async function deleteVideo(id, navigate) {
  const res = await fetch(`${deleteVideoEndpoint}/${id}/video`, {
      method: "DELETE",
      headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("auth")
      }
  })

  if (res.status === 204) {
      navigate(videoPath)
  } else if (res.status === 404) {
    const data = await res.json();
    updateVideoAlertError(data["detail"]);
  }
}


export async function searchVideo(tag, setVideoData) {
  const res = await fetch(`${searchVideoEndpoint}?tag=${tag}&page=1`, {
    method: "GET",
  })

  const data = await res.json();

  if (res.status === 200 ) {
    setVideoData(data)

  } else if (res.status === 400) {
    const data = await res.json();
    console.log(data)
  }

}