import { useEffect, useState, useRef } from "react";
import Navbar from "../../components/navbar.js";
import { checkIsAuthenticateHome, searchVideo } from "../../components/axios.js";
import VideoPlayer from "../../components/player";
import LoadingSpinner from "../../components/spinners.js";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import "./index.css";



const Home = () => {
  const [data, setData] = useState(null);
  const [videoData, setVideoData] = useState([]);
  const [word, setWord] = useState("");
  const [isAuthenticate, setAuthenticate] = useState(false);
  const sendingTap = useRef(false);

  useEffect(() => {
    checkIsAuthenticateHome(setAuthenticate, setData);
  }, []);

  useEffect(() => {
    if (!sendingTap.current) {
      return;
    }
    console.log(videoData)
  }, [videoData]);

  function changeWord(e) {
    setWord(e.target.value)
  }

  function search() {
    searchVideo(word, setVideoData);
    sendingTap.current = true;
  }

  if (data === null) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <header>
        <Navbar authenticate={isAuthenticate} />
      </header>

      <div className="video-search-div">
        <div className="video-search-inner-div">
        </div>
      </div>
      <Form className="search-form">
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>查詢你感興趣的影片</Form.Label>
          <Form.Control type="text" onChange={changeWord} />
        </Form.Group>
        <div className="form-button mt-3">
            <button type="button" onClick={search} className="btn btn-primary">查詢</button>
        </div>
      </Form>
    </>
  );
};

export default Home;
