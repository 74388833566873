const apiHost = "https://api-tc.neochang.com";

export const homePath = "/";
export const loginPath = "/login";
export const signupPath = "/signup";
export const memberPath = "/member";
export const memberAvatarPath = "/member/avatar";
export const videoPath = "/videos";
export const videoEditPath = "/profile/:id/video";
export const videoCreatePath = "/video/create";

export const videoSize = 10;

export const loginEndpoint = `${apiHost}/api/login`;
export const signupEndpoint = `${apiHost}/api/register`;
export const userEndpoint = `${apiHost}/api/profile`;
export const updateUserEndpoint = `${apiHost}/api/update/user`;
export const updateUserAvatarEndpoint = `${apiHost}/api/update/user/avatar`;
export const userVideosEndpoints =`${apiHost}/api/profile/videos`;
export const userVideoEndpoint = `${apiHost}/api`;
export const searchVideoEndpoint = `${apiHost}/search`;
export const createVideoEndpoint = `${apiHost}/api/profile/create/video`;
export const editVideoEndpoint = `${apiHost}/api/profile/update`;
export const deleteVideoEndpoint = `${apiHost}/api/profile/delete`;
export const loginFormMissingField = "欄位缺少";
export const loginEmailMinLengthError = "信箱欄位不能為空";
export const loginPasswordMinLengthError = "密碼欄位不能為空";
export const loginEmailInputErrorMessage = "信箱格式錯誤";
export const loginPasswordInputErrorMessage = "密碼格式錯誤";
export const signupFormMissingField = "欄位缺少";
export const signupEmailMinLengthError = "信箱欄位不能為空";
export const signupPasswordMinLengthError = "密碼欄位不能為空";
export const signupEmailInputErrorMessage = "信箱格式錯誤";
export const signupPasswordInputErrorMessage = "密碼格式錯誤";

export const memberNicknameMinLength = 0;
export const memberNicknameMaxLength = 64;
export const memberNicknameErrorMessage = `暱稱長度過長，請低於 ${memberNicknameMaxLength} 長度`;
export const memberNicknameEmptyErrorMessage = "暱稱不允許空白";
export const memberAvatarInvalidFileErrorMessage = "錯誤的照片圖檔格式";
export const memberAvatarSize = 2;
export const memberAvatarOverSizeErrorMessage = `大頭貼檔案大小請低於 ${memberAvatarSize} MB`;
export const memberAvatarNotLoadErrorMessage = "請選擇上傳的大頭貼";

export const memeberVideoNameMinLength = 0;
export const memeberVideoNameMaxLength = 64;
export const memberVideoNameErrorMessage = `影片名稱長度過長，請低於 ${memeberVideoNameMaxLength} 長度`;
export const memberVideoEmptyErrorMessage = "影片名稱不允許空白";
export const memeberVideoInformationMaxLength = 200;
export const memberVideoInformationErrorMessage = `影片描述長度過長，請低於 ${memeberVideoInformationMaxLength} 長度`;
export const memberVideoInvalidFileErrorMEssage = "錯誤的影片檔案格式";
export const memberVideoFileSize = 5;
export const memberVideoOverSizeErrorMessage = `大頭貼檔案大小請低於 ${memberVideoFileSize} MB`;
export const memberVideoNotLoadErrorMessage = "請選擇上傳的影片";
