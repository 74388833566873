import ReactPlayer from "react-player";
import "../index.css";

const VideoPlayer = ({ url, width, height }) => {
    return (
        <div className="player-wrapper">
            <ReactPlayer
                className="react-player"
                width={width}
                height={height}
                url={url}
                controls={true}
                playsinline
            />
        </div>
    )
}

export default VideoPlayer;
