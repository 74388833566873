import { useEffect, useRef, useState } from "react";
import { Form, Alert } from "react-bootstrap";
import { updateUser } from "../../../components/axios";
import {
  memberNicknameMinLength,
  memberNicknameMaxLength,
  memberNicknameErrorMessage,
  memberNicknameEmptyErrorMessage
} from "../../../global/constants";
import "../index.css";


const Basic = ({ data, nickname, setNickname }) => {
    const [updateData, setUpdateData] = useState(null);
    const [erroNickname, setErrorNickname] = useState("");
    const sendingTap = useRef(false);
  
    useEffect(() => {
      if (!sendingTap.current) {
        return;
      }
  
      updateUser(updateData, setNickname);
      sendingTap.current = false;
    }, [updateData, setNickname]);

    function changedNickname(e) {
      if (e.target.value.length > memberNicknameMaxLength) {
        setErrorNickname(memberNicknameErrorMessage);
      } else if (e.target.value.length <= memberNicknameMinLength) {
        setErrorNickname(memberNicknameEmptyErrorMessage);
      }else {
        setErrorNickname(null);
      }
  
      setNickname(e.target.value);
    }
  
    function saveProfile(e) {
      sendingTap.current = true;
      setUpdateData(function () {
        return {
          nickname: nickname,
        };
      });
    }
  
    return (
        <div className="col-lg-9">
            <div className="px-3">
                <form>
                    <div className="form-group mb-3">
                    <Form.Label>信箱</Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        defaultValue={data["email"]}
                        readOnly
                    />
                    </div>
                    <div className="form-group mb-3">
                    <Form.Label>暱稱</Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        value={nickname}
                        onChange={changedNickname}
                        maxLength="64"
                    />
                    </div>
                    {erroNickname && (
                    <Alert key="nicknameErrorDanger" variant="danger">
                        {erroNickname}
                    </Alert>
                    )}
                    <div className="form-group mb-3">
                    <Form.Label>狀態</Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        defaultValue="√"
                        readOnly
                    />
                    </div>
                    <div className="form-group mb-3">
                    <Form.Label>創建日期</Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        defaultValue={data["createdAt"]}
                        readOnly
                    />
                    </div>
                    <button
                        type="button"
                        onClick={saveProfile}
                        className="btn btn-light"
                    >送出</button>
                </form>
            </div>
        </div>
    )
}

export default Basic