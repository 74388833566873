import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Alert, Button, Modal } from "react-bootstrap";
import { getUserVideo, updateVideo, deleteVideo } from '../../../components/axios';
import {
    memeberVideoNameMinLength,
    memeberVideoNameMaxLength,
    memberVideoNameErrorMessage,
    memeberVideoInformationMaxLength,
    memberVideoInformationErrorMessage,
    memberVideoEmptyErrorMessage
} from '../../../global/constants';
import LoadingSpinner from '../../../components/spinners';
import Navbar from '../../../components/navbar';
import VideoPlayer from '../../../components/player';
import "../index.css";


const VideoEdit = () => {
    const [data, setData] = useState(null);
    const [name, setName] = useState("");
    const [errorName, setErrorName] = useState(null);
    const [information, setInformation] = useState("");
    const [errorInformation, setErrorInformation] = useState(null);
    const [videoLink, setVideoLink] = useState("");
    const [isAuthenticate, setAuthenticate] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const nameValidRef = useRef(true);
    const inoformationValidRef = useRef(true);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getUserVideo(id, setData, setAuthenticate, setName, setInformation, setVideoLink, navigate)

    }, [id, navigate])

    function changeName(e) {
        if (e.target.value.length > memeberVideoNameMaxLength) {
            setErrorName(memberVideoNameErrorMessage);
            nameValidRef.current = false;
        } else if (e.target.value.length <= memeberVideoNameMinLength) {
            setErrorName(memberVideoEmptyErrorMessage);
            nameValidRef.current = false;

        } else {
            setErrorName(null);
            nameValidRef.current = true;
        }

        setName(e.target.value)
    }

    function changeInformation(e) {
        if (e.target.value.length > memeberVideoInformationMaxLength) {
            setErrorInformation(memberVideoInformationErrorMessage);
            inoformationValidRef.current = false;
        } else {
            setErrorInformation(null);
            inoformationValidRef.current = true;
        }

        setInformation(e.target.value)        
    }

    function saveVideo() {
        if (!nameValidRef.current || !inoformationValidRef.current) {
            return
        }

        updateVideo(
            {
                name: name,
                information: information,
                id: id
            },
            navigate
        );
    }

    function saveModal() {
        deleteVideo(id, navigate);
        handleClose()
    }

    if (data === null) {
        return <LoadingSpinner />
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body>你確定要刪除這個影片內容嗎</Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose}>
                        取消
                    </Button>
                    <Button variant="primary" onClick={saveModal}>
                        確定
                    </Button>
                </Modal.Footer>
            </Modal>

            <header>
                <Navbar authenticate={isAuthenticate} />
            </header>
            <div className="video-edit-div">
                <div className="video-edit-inner-div">
                    <Button className="video-edit-previous-btn" onClick={() => navigate(-1)} variant="outline-primary">上一頁</Button>
                    
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>影片描述</Form.Label>
                        <Form.Control onChange={changeInformation} as="textarea" rows={3} defaultValue={information}/>
                    </Form.Group>
                    
                    {errorInformation && (
                        <Alert key="informationErrorDanger" variant="danger">
                            {errorInformation}
                        </Alert>
                    )}
                    
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>影片標籤</Form.Label>
                        <div class="bg-white">
                            <Form.Control onChange={changeName} type="text" defaultValue={name} />
                        </div>
                    </Form.Group>
                    
                    {errorName && (
                        <Alert key="nameErrorDanger" variant="danger">
                            {errorName}
                        </Alert>
                    )}
                    <VideoPlayer url={videoLink} width="100%" height="100%" />
                    <div className="video-edit-button-group">
                        <button
                                type="button"
                                onClick={saveVideo}
                                className="btn btn-light me-1"
                            >
                                送出
                        </button>
                        <button
                            type="button"
                            onClick={handleShow}
                            className="btn btn-danger"
                        >刪除</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VideoEdit
