import Item from "./Item";
import "../../../index.css";


const List = ({ videoData }) => {
    return (
        <>
            <div className="video-list">
                {videoData["items"].map((item) => {
                    const { id, label, information, url, created_at } = item;
                    return (
                        <Item
                            key={id}
                            id={id}
                            label={label}
                            url={url}
                            information={information}
                            created_at={created_at}
                        />
                    );
                })}
            </div>
        </>
    )
}

export default List;
