import { Link, generatePath } from "react-router-dom";
import { videoEditPath } from "../../../global/constants";
import VideoPlayer from "../../../components/player";
import "../index.css"


const Item = ({id, label, information, url, created_at}) => {
    return (
          <>
            <div className="card mt-2 mb-2 video-card-plugin">
              <VideoPlayer url={url} width="340px" height="100%" />
              <div className="card-body card-body-width">
                <p className="card-text">影片描述：{information}</p>
                {label?.["tags"] ? (
                    <p className="card-title">影片標籤：
                      {label?.["tags"].map((item) => {
                        return <span className="card-title">#{item} </span>
                      })}
                    </p>
                  ) : (
                    <p className="card-title">影片標籤：無</p>
                  )            
                }
                <Link 
                    to={generatePath(videoEditPath, {id: id})}
                >編輯</Link>
              </div>
              <span className="card-created-at">建立時間：{created_at}</span>
            </div>
          </>
    )
}

export default Item;
