import ReactPaginate from 'react-paginate';
import "../index.css";


const Paginations = ({ videoData, setCurrentPage }) => {
    const pageCount = Math.ceil(videoData["total"] / videoData["size"]);
  
    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
    };


    return (
        <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
        />
    );

};

export default Paginations