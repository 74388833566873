import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Alert, Button } from "react-bootstrap";
import { checkIsAuthenticate, createVideo } from '../../../components/axios';
import {
    memberVideoNameErrorMessage,
    memberVideoInvalidFileErrorMEssage,
    memberVideoFileSize,
    memberVideoOverSizeErrorMessage,
    memberVideoNotLoadErrorMessage,
    memberVideoInformationErrorMessage,
    memeberVideoNameMaxLength,
    memeberVideoInformationMaxLength
} from "../../../global/constants";
import Navbar from '../../../components/navbar';
import "../index.css";


const VideoCreate = () => {
    const [name, setName] = useState("");
    const [errorName, setErrorName] = useState(null);

    const [information, setInformation] = useState("");
    const [errorInformation, setErrorInformation] = useState(null);

    const [tags, setTags] = useState([]);
    const [errorTags, setErrorTags] = useState(null);

    const [isAuthenticate, setAuthenticate] = useState(false);
    const navigate = useNavigate()
    const nameValidRef = useRef(false);
    const inoformationValidRef = useRef(true);
    const videoRef = useRef(null);

    useEffect(() => {
        checkIsAuthenticate(setAuthenticate, navigate);
      }, [navigate]);

    function changeName(e) {
        if (e.target.value.length > memeberVideoNameMaxLength) {
            setErrorName(memberVideoNameErrorMessage);
            nameValidRef.current = false;
        } else {
            setErrorName(null);
            nameValidRef.current = true;
        }

        setName(e.target.value)
    }

    function changeInformation(e) {
        if (e.target.value.length > memeberVideoInformationMaxLength) {
            setErrorInformation(memberVideoInformationErrorMessage);
            inoformationValidRef.current = false;
        } else {
            setErrorInformation(null);
            inoformationValidRef.current = true;
        }

        setInformation(e.target.value)        
    }

    function changeTags(e) {
        if (e.key === ' ' && e.target.value.trim()) {
            const newTag = e.target.value.trim()
            setTags(initArray => [...initArray, newTag])
            e.target.value = ''
        }
    }

    function removeTag(index) {
        const initTags = [...tags]
        initTags.splice(index, 1)
        setTags(initTags)
    }

    function isValidVideoExtension(filename) {
        return /\.(mp4|mp3|)$/.test(filename);
    }

    function saveVideo() {
        const file = videoRef.current?.files[0];
    
        if (file) {
          if (!isValidVideoExtension(file["name"])) {
            alert(memberVideoInvalidFileErrorMEssage);
            return;
          }
          if (file.size / 1024 / 1024 > memberVideoFileSize) {
            alert(memberVideoOverSizeErrorMessage);
            return;
          }
    
          if (!nameValidRef.current || !inoformationValidRef.current) {
            return
          }

          const formData = new FormData();
          formData.append("upload_file", file);
          formData.append("name", name);
          formData.append("information", information);

          createVideo(formData, navigate);
        } else {
          alert(memberVideoNotLoadErrorMessage);
        }
      }

    return (
        <>
            <header>
                <Navbar authenticate={isAuthenticate} />
            </header>
            <div className="form-body">
                <div className="row">
                    <div className="form-holder">
                        <div className="form-content">
                        <Button className="video-create-previous-btn" onClick={() => navigate(-1)} variant="outline-primary">上一頁</Button>

                            <div className="form-items">
                                <h3>新增影片</h3>
                                <p>請填寫以下欄位</p>
                                <form className="requires-validation">
                                    <div className="col-md-12">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>
                                                影片描述
                                                <span className='video-input-span'>選填</span>
                                            </Form.Label>
                                            <Form.Control onChange={changeInformation} as="textarea" rows={3} defaultValue={information}/>
                                        </Form.Group>
                                        {errorInformation && (
                                            <Alert key="informationErrorDanger" variant="danger">
                                                {errorInformation}
                                            </Alert>
                                        )}
                                    </div>

                                    <div className="col-md-12">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>
                                                影片標籤
                                                <span className='video-input-span'>選填</span>
                                            </Form.Label>
                                            <div className="relative bg-white rounded rounded-3 d-flex align-items-center">
                                                {tags.map((tag, index) => {
                                                    return <span className="w-auto d-flex m-1 p-1 align-items-center text-bg-primary">
                                                        <span className="px-1 text-sm d-flex">
                                                            {tag}
                                                            <span className="border border-1 text-sm px-1 ms-1" role="button" onClick={() => removeTag(index)}>X</span>
                                                        </span>
                                                    </span>
                                                })}
                                                <Form.Control className="video-create-text-input"  onKeyPress={changeTags} type="text" defaultValue={''}/>
                                            </div>
                                        </Form.Group>
                                        {errorInformation && (
                                            <Alert key="tagsErrorDanger" variant="danger">
                                                {errorTags}
                                            </Alert>
                                        )}
                                    </div>
                                    <div className="col-md-12">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>
                                                影片檔案
                                                <span className='video-input-span'>必填</span>
                                            </Form.Label>
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                ref={videoRef}
                                                type="file"
                                                accept="video/mp3, video/mp4"
                                                />
                                            </Form.Group>
                                        </Form.Group>
                                    </div>

                                    <div className="form-button mt-3">
                                        <button type="button" onClick={saveVideo} className="btn btn-primary">新增</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default VideoCreate;