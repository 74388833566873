import { useRef } from "react";
import { Form } from "react-bootstrap";
import { updateUserAvatar } from "../../../components/axios";
import {
  memberAvatarSize,
  memberAvatarInvalidFileErrorMessage,
  memberAvatarOverSizeErrorMessage,
  memberAvatarNotLoadErrorMessage
} from "../../../global/constants";
import "../index.css";


const MemberAvatar = ({ avatarLink, setAvatarLink }) => {
    const avatarRef = useRef(null);

    function isValidAvatarExtension(filename) {
      return /\.(jpg|jpeg|png|gif)$/.test(filename);
    }
  
    function saveAvatar() {
      const file = avatarRef.current?.files[0];
  
      if (file) {
        if (!isValidAvatarExtension(file["name"])) {
          alert(memberAvatarInvalidFileErrorMessage);
          return;
        }
        if (file.size / 1024 / 1024 > memberAvatarSize) {
          alert(memberAvatarOverSizeErrorMessage);
          return;
        }
  
        const formData = new FormData();
        formData.append("upload_file", file);
  
        updateUserAvatar(formData, setAvatarLink);
      } else {
        alert(memberAvatarNotLoadErrorMessage);
      }
    }

    return (
        <>
            <div className="col-lg-9 d-none d-lg-inline-block">
              <img className="avatar" src={avatarLink} alt="" />
              <Form.Group className="mb-3">
              <Form.Control
                  ref={avatarRef}
                  className="file-input avatar-btn btn btn-light"
                  type="file"
                  accept="image/*"
              />
              </Form.Group>
              <button
              type="button"
              onClick={saveAvatar}
              className="btn btn-light avatar-btn"
              >
              送出
              </button>
            </div>
        </>
    )
}

export default MemberAvatar;