import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getMember } from "../../components/axios";
import Basic from "./components/basic";
import MemberAvatar from "./components/avatar";
import MemeberSidebar from "./components/sidebar";
import Navbar from "../../components/navbar";
import LoadingSpinner from "../../components/spinners";
import "./index.css";


const Member = () => {
  const [data, setData] = useState(null);
  const [nickname, setNickname] = useState("");
  const [avatarLink, setAvatarLink] = useState("");
  const [isAuthenticate, setAuthenticate] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (data) {
      return;
    }

    getMember(setAuthenticate, setNickname, setAvatarLink, setData, navigate);
  }, [data, navigate]);

  if (data === null) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <header>
        <Navbar authenticate={isAuthenticate} />
      </header>
      <div id="main-wrapper" className="container">
        <div className="row justify-content-center member-div">
          <div className="col-xl-10">
            <div className="row member-board">
              <nav id="sidebarMenu" className="collapse d-lg-block sidebar collapse bg-white">
                <div className="position-sticky" style={{ "display": "flex"}}>
                  {location.pathname === "/member" ? (
                    <>
                      <MemeberSidebar />
                      <Basic data={data} nickname={nickname} setNickname={setNickname} />
                    </>
                  ) : (
                    <>
                      <MemeberSidebar />
                      <MemberAvatar avatarLink={avatarLink} setAvatarLink={setAvatarLink} />
                    </>
                  )}

                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Member;
