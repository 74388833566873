import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  homePath,
  loginPath,
  signupPath,
  memberPath,
  memberAvatarPath,
  videoPath,
  videoEditPath,
  videoCreatePath
} from "./global/constants";
import ErrorPage from "./components/errorPage";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Member from "./pages/Member";
import Video from "./pages/Video";
import Signup from "./pages/Signup";
import VideoEdit from "./pages/Video/components/Edit";
import VideoCreate from "./pages/Video/components/New";
import "./index.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

const router = createBrowserRouter([
  {
    path: homePath,
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: loginPath,
    element: <Login />,
  },
  // {
  //   path: signupPath,
  //   element: <Signup />
  // },
  {
    path: memberPath,
    element: <Member />,
  },
  {
    path: memberAvatarPath,
    element: <Member />
  },
  {
    path: videoPath,
    element: <Video />
  },
  {
    path: videoEditPath,
    element: <VideoEdit />
  },
  {
    path: videoCreatePath,
    element: <VideoCreate />
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);
