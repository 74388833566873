import { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import {
  loginEmailInputErrorMessage,
  loginEmailMinLengthError,
  loginPasswordInputErrorMessage,
  loginPasswordMinLengthError,
  homePath,
  signupPath,
} from "../../global/constants";
import LoadingSpinner from "../../components/spinners";
import "./index.css";
import { AuthenticateRedirect, sendLoginEndpoint } from "../../components/axios";


const Login = () => {
  const [loginData, setLoginData] = useState({});
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(null);
  const [password, setPassword] = useState("");
  const [errorPassowrd, setErrorPassowrd] = useState(null);

  const sendingTap = useRef(false);
  const navigate = useNavigate();

  const emailValidRef = useRef(false);
  const passwordValidRef = useRef(true);

  useEffect(() => {
    if (localStorage.getItem("auth")) {
      AuthenticateRedirect(navigate)
    }

    if (!sendingTap.current) {
      return;
    }

    sendLoginEndpoint(loginData, navigate);
  }, [loginData, navigate]);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function isValidPassword(password) {
    return /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,12}/.test(password);
  }

  function changeEmail(e) {
    if (e.target.value.length <= 0) {
      setErrorEmail(loginEmailMinLengthError)
      emailValidRef.current = false;

    } else if (!isValidEmail(e.target.value)) {
      setErrorEmail(loginEmailInputErrorMessage);
      emailValidRef.current = false;

    } else {
      setErrorEmail(null);
      emailValidRef.current = true;
    }

    setEmail(e.target.value);
  }

  function changePassword(e) {
     if (e.target.value.length <= 0) {
      setErrorPassowrd(loginPasswordMinLengthError)
      passwordValidRef.current = false;
    } else if (!isValidPassword(e.target.value)) {
      setErrorPassowrd(loginPasswordInputErrorMessage);
      passwordValidRef.current = false;
    }  else {
      setErrorPassowrd(null);
      passwordValidRef.current = true;
    }

    setPassword(e.target.value);
  }

  function collectCredentials() {
    if (errorEmail !== null || errorPassowrd !== null) {
      return;
    }

    if (!emailValidRef.current || !passwordValidRef.current) {
      return;
    }

    sendingTap.current = true;
    setLoginData(function () {
      return {
        email: email,
        password: password,
      };
    });
  }

  if (loginData === {}) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="form-bg">
        <div className="container">
          <div className="row login-div">
            <div className="col-md-offset-3 col-md-6 col-sm-offset-2 col-sm-8">
              <div className="form-container">
                <form className="form-horizontal">
                  {errorEmail && (
                    <Alert key="emailErrorDanger" variant="danger">
                      {errorEmail}
                    </Alert>
                  )}
                  {errorPassowrd && (
                    <Alert key="passwordErrorDanger" variant="danger">
                      {errorPassowrd}
                    </Alert>
                  )}
                  <h3 className="title">My Account</h3>
                  <span className="description">
                    <Link to={homePath}>Go Back Home Page</Link>
                  </span>
                  <div className="form-group">
                    <Form.Group className="mb-3">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        value={email}
                        onChange={changeEmail}
                        placeholder="name@example.com"
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className="form-group">
                    <Form.Group className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        value={password}
                        onChange={changePassword}
                        required
                      />
                    </Form.Group>
                  </div>
                  <button
                    type="button"
                    className="btn signin"
                    onClick={collectCredentials}
                  >
                    Login
                  </button>
                  <span className="signup">or <Link to={signupPath}>Sign up</Link></span>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
