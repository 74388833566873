import { Link } from "react-router-dom";
import { memberPath, memberAvatarPath } from "../../../global/constants";


const MemeberSidebar = () => {
    return (

        <div className="list-group list-group-flush col-lg-3">
        <Link
          to={memberPath}
          className="list-group-item list-group-item-action py-2 ripple"
          aria-current="true"
        >
          <i className="fas fa-tachometer-alt fa-fw me-3"></i><span>個人資訊</span>
        </Link>
        <Link
          to={memberAvatarPath}
          className="list-group-item list-group-item-action py-2 ripple"
        >
          <i className="fas fa-chart-area fa-fw me-3"></i><span>大頭貼</span>
        </Link>
      </div>

    )
}

export default MemeberSidebar;