import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Offcanvas, Nav } from "react-bootstrap";
import { sendLogoutEndpoint } from "./axios";
import { homePath, loginPath, signupPath, memberPath, videoPath } from "../global/constants";


const Navbar = ({ authenticate }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [logout, setLogout] = useState(false);
  const logoutRef = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!logoutRef.current) {
      return
    }

    sendLogoutEndpoint(navigate, location);
  }, [logout, navigate, location])

  function changeLogoutRef() {
    console.log("logout")
    logoutRef.current = true;
    setLogout(true);
  }


  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid navbar-fluid">
        {authenticate ? (
          <>
            <svg onClick={handleShow} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-list hamberger-svg" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
            </svg>

            <Offcanvas show={show} onHide={handleClose} placement="end">
              <Offcanvas.Body>
              <div className="list-group list-group-flush">
                <Nav.Link
                  href={homePath}
                  className="list-group-item list-group-item-action py-2 ripple"
                  aria-current="true"
                >
                  <i className="fas fa-tachometer-alt fa-fw me-3"></i><span>首頁</span>
                </Nav.Link>
                <Nav.Link
                  href={memberPath}
                  className="list-group-item list-group-item-action py-2 ripple"
                >
                  <i className="fas fa-chart-area fa-fw me-3"></i><span>個人頁面</span>
                </Nav.Link>
                <Nav.Link
                  href={videoPath}
                  className="list-group-item list-group-item-action py-2 ripple"
                  aria-current="true"
                >
                  <i className="fas fa-tachometer-alt fa-fw me-3"></i><span>影片頁面</span>
                </Nav.Link>
                <Nav.Link
                  href="#"
                  onClick={changeLogoutRef}
                  className="list-group-item list-group-item-action py-2 ripple"
                >
                  <i className="fas fa-chart-area fa-fw me-3"></i><span>登出</span>
                </Nav.Link>
              </div>
              </Offcanvas.Body>
            </Offcanvas>
          </>
        ) : (
          <>
            <div className="navbar-auth">
              {/* <Nav className="ml-auto my-2 my-lg-0">
                <Nav.Link className="dropdown-item" href={signupPath}>註冊</Nav.Link>
              </Nav> */}
              <Nav>
                <Nav.Link className="dropdown-item" href={loginPath}>登入</Nav.Link>
              </Nav>
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
