import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar";
import LoadingSpinner from "../../components/spinners";
import Paginations from "../../components/pagination";
import { getUserVideos } from "../../components/axios";
import { videoCreatePath } from "../../global/constants";
import List from "./components/List";


const Video = () => {
    const [data, setData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isAuthenticate, setAuthenticate] = useState(false);
    const navigate = useNavigate();
    const ref = useRef(false);

    useEffect(() => {
        getUserVideos(currentPage, setAuthenticate, setData, navigate, ref);
    }, [currentPage, navigate])

    if (data === null) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <header>
                <Navbar authenticate={isAuthenticate} />
            </header>
            <div className="video-basic">
                <div className="video-create">
                    <Link to={videoCreatePath} className="btn btn-primary">新增影片</Link>
                </div>
                <hr className="video-hr" />
                {data["videos"]["items"] ? (
                    <>
                        <List videoData={data["videos"]} />
                        <div className='paginationDiv'>
                            <Paginations videoData={data["videos"]} setCurrentPage={setCurrentPage} />
                        </div>
                    </>
                ) : (
                    <>
                        <h1 className="video-empty-h1">尚未上傳任何影片</h1>
                    </>
                )}
            </div>
        </>
    )
}

export default Video
